function App() {
  return (
    <div>
      <a
        href='https://flower-remedy.shop/#a_aid=6105458043254&amp;a_bid=a0724d12'
        target='_top'
      >
        <img
          src='//flower-remedy-jp.postaffiliatepro.com/accounts/default1/z9h7dvexb4k/a0724d12.jpg'
          alt='フラワーレメディ'
          title='フラワーレメディ'
          width='300'
          height='250'
        />
      </a>
      <img
        style={{border: '0'}}
        src='https://flower-remedy-jp.postaffiliatepro.com/scripts/z9h7dvexi4k?a_aid=6105458043254&amp;a_bid=a0724d12'
        width='1'
        height='1'
        alt=''
      />
    </div>
  );
}

export default App;
